<template>
    <step-settings-wrapper title="Save & Settings" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-xl sm:text-3xl uppercase font-bold tracking-wider leading-relaxed sm:leading-normal">
                    Save to Boothclub Gallery
                </div>
                <div class="float-right pt-0 md:pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="saveToAWS" :value="saveToAWS" @change="updateToggleValue($event)"></toggle-button>
                </div>
            </div>
            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-xl sm:text-3xl uppercase font-bold tracking-wider leading-relaxed sm:leading-normal">
                    Save to Camera Roll
                </div>
                <div class="float-right pt-0 md:pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="saveToCameraRoll" :value="saveToCameraRoll" @change="updateToggleValue($event)"></toggle-button>
                </div>
            </div>

        </div>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue'
    import { ToggleButton } from 'vue-js-toggle-button'

    export default {
        name: 'SaveSettings',
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        data: function() {
            return {
                saveToAWS: this.preset.saveSettings.saveToAWS === 'true' || this.preset.saveSettings.saveToAWS === true,
                saveToCameraRoll: this.preset.saveSettings.saveToCameraRoll === 'true' || this.preset.saveSettings.saveToCameraRoll === true,
                beforeEditing: {}
            }
        },
        components: {
            StepSettingsWrapper,
            ToggleButton
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.saveSettings);
        },
        methods: {
            updateToggleValue: function(event) {
                if( event.value === true ) {
                    this.preset.saveSettings[event.srcEvent.srcElement.name] = 'true';
                } else {
                    this.preset.saveSettings[event.srcEvent.srcElement.name] = 'false';
                }
            },
            onClose: function(toRoute) {
                //this.preset.saveSettings = Object.assign({}, this.beforeEditing);
                let filesSelected = 0;
                this.$emit('close', 'saveSettings', this.beforeEditing, filesSelected, toRoute);
            },
            onSave: function() {
                this.$emit('save', 'saveSettings');
            }
        }
    }
</script>

<style scoped>
    @media (max-width: 374px) {
        .xs-text-xs {
            font-size: 16px;
            position: relative;
            top: 4px;
        }
    }
</style>